.loader-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30px;
}

.loader-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -20px;
}