.header-wrapper {
  position: relative;
  padding: 0;
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.header-collapsed {
  padding: 22px 24px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  font-size: 20px;
}

.header-collapsed:hover,
.header-dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.header-user-info {
  float: right;
  height: 100%;
  overflow: hidden;
}

.header-user-info .action {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.header-user-info .action > i {
  vertical-align: middle;
}

.header-user-info .action:hover {
  background: rgba(0, 0, 0, 0.025);
}

.header-user-info .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}

.header-user-info .search {
  padding: 0 12px;
}

.header-user-info .search:hover {
  background: transparent;
}

.header-user-info .account .avatar {
  margin: calc((- 24px) / 2) 0;
  margin-right: 8px;
  /* color: ; */
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}

.header-dropdown-link {
  display: inline-block;
  padding: 0 15px;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  cursor: pointer;
}

.header-dropdown-link span:first-child {
  font-size: 20px !important;
}

.header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  transition: width 0.2s;
}

@media only screen and (max-width: 768px) {
  .name {
    display: none;
  }
  .header-user-info {
    position: absolute;
    top: 0;
    right: 12px;
    background: #fff;
  }
}
