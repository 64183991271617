.steps-content {
  margin-top: 1rem;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  /* background-color: #fafafa; */
  min-height: 200px;
  /* text-align: center; */
  padding: 1rem;
}

.steps-action {
  margin-top: 24px;
}
