.integration-success.is-mobile .ant-result {
  padding: 16px 16px;
}

.integration-success.is-mobile .ant-result-title {
  font-size: 16px;
}

.integration-success.is-mobile .ant-result-icon i {
  font-size: 48px;
}
