.provider-wrap {
  display: flex;
  word-break: normal;
  overflow-wrap: normal;
}

.template-content {
  flex: 1;
}

.provider-insideContent {
  flex: 1;
}

.extraContent {
  display: none;
}

.template-contentLink {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
}

.template-contentLink a {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 32px;
}

.template-contentLink a img {
  margin-right: 8px;
}

.template-page .ant-page-header {
  padding: 0;
}

.template-list .ant-card-meta-description {}

.template-list .cardTitle a {
  display: inline-block;
  height: 24px;
  margin-left: 12px;
  line-height: 24px;
  vertical-align: top;
}

.template-list {
  width: 100%;
}

.projectItemContent {
  display: flex;
  height: 20px;
  margin-top: 8px;
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.projectItemContent a {
  display: inline-block;
  flex: 1 1 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.projectItemContent .datetime {
  flex: 0 0 auto;
  float: right;
  color: lightgray;
}

#providerCard {
  width: 100%;
}

/* Google (xs) */

@media (min-width: 320px) {
  .ant-page-header {
    /* padding: 24px; */
  }
}

/* Small (sm) */

@media (min-width: 640px) {
  .template-list .ant-card-meta-description {
    height: 100px;
    line-height: 22px;
    overflow: auto;
  }
  .template-contentLink {
    padding-top: 16px;
  }
  .template-contentLink a {
    display: inline-block;
    vertical-align: text-top;
    margin-right: 32px;
  }
  .template-contentLink a img {
    margin-right: 8px;
  }
  .extraContent {
    display: block;
    min-width: 240px;
    text-align: right;
  }
  .projectGrid {
    width: 50%;
  }
  #providerCard {
    width: 50%;
  }
}

/* Medium (md) */

@media (min-width: 768px) {
  #providerCard {
    width: 50%;
  }
}

/* Large (lg) */

@media (min-width: 1024px) {
  #providerCard {
    width: 33.33%;
  }
}

/* Extra Large (xl) */

@media (min-width: 1280px) {}