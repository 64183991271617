#apiKeys .ant-list-item-meta .iconImg {
  display: block;
  font-size: 32px;
  line-height: 32px;
}

#apiKeys .ant-list-item-meta {
  align-items: center;
}

.ant-collapse-borderless {
  background-color: white;
  border: 0;
}