.headerSearch .anticon-search {
  font-size: 16px;
  cursor: pointer;
  padding-left: 10px;
}

.headerSearch .input {
  width: 0;
  background: transparent;
  border-radius: 0;
  transition: width 0.3s, margin-left 0.3s;
}

.headerSearch .input .ant-select-selection {
  background: transparent;
}

.headerSearch .input input {
  padding-right: 0;
  padding-left: 0;
  border: 0;
  box-shadow: none !important;
}

.headerSearch .input,
.headerSearch .input:hover,
.headerSearch .input:focus {
  display: none;
  border-bottom: 1px solid;
}

.headerSearch .input.show {
  display: inline-block;
  width: 210px;
  margin-left: 8px;
}
