/* Must use important flag to override ant design important flag */

#sidebarItem {
  padding: 0 7px !important;
}

#sidebarItem .ant-menu-submenu-title {
  padding: 0 !important;
}

.logo {
  position: relative;
  padding-left: 8px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  background: #01572c;
  overflow: hidden;
}

.logo img {
  display: inline-block;
  height: 36px;
}

.logo h1 {
  display: inline-block;
  margin-left: 12px;
  color: #fff;
  vertical-align: middle;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.logo span {
  font-size: 20px;
  float: right;
  margin-right: 6px;
  margin-top: 22px;
  margin-bottom: 22px;
  color: white;
}
