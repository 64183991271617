.content {
    margin: 0px;
}

.overview .ant-card-head {
    padding: 0 0 0 10px;
}

.overview .ant-list-lg .ant-list-item {
    padding-top: 4px;
    padding-bottom: 4px;
}

.overview .ant-list-item-meta {
    color: black;
    padding: 0 0 0 10px;
    line-height: 10px;
}

.overview .ant-list-item-meta-title {
    line-height: 10px;
}

.overview .ant-list-item-meta-description {
    font-size: 10px;
}

.button-group {}