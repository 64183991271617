.panelContainer .ant-card-head {
  min-height: 24px;
  padding: 0 12px;
}

.panelContainer .ant-card-head-title {
  cursor: move;
}

.panelContainer .ant-card-head-wrapper {
  display: flex;
}

.panelContainer .ant-card-head .ant-card-head-wrapper {
  height: 24px;
}

.panelContainer .ant-card-head .ant-card-head-title {
  font-size: 12px;
}

.panelContainer .ant-card-head .ant-card-extra {
  font-size: 10px;
}

.panelContainer .ant-card-head .ant-card-extra i {
  font-size: 14px;
  padding-left: 0.5rem;
}

.panelContainer .ant-table {
  font-size: 10px;
  white-space: nowrap;
}

#full {
  height: 100vh !important;
  width: 100vw !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}
