#template-frame .ant-upload-list-picture-card-container {
    float: left;
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 8px 8px 0;
}

#template-frame .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 8px 8px 0;
}

#template-frame i {
    z-index: 10;
    width: 32px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 32px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Styles */
}

/* Desktops and laptops ----------- */

@media only screen and (min-width: 1224px) {}

/* Large screens ----------- */

@media only screen and (min-width: 1824px) {}