.activitiesList {
    color: black;
    padding: 0 10px 0 0;
}

.activitiesList .ant-list-items {
    font-size:12px;
}

.activitiesList .ant-descriptions-title {
    padding: 0 10px 0 0;
}

.activitiesList .ant-list-item-meta-title {
    font-size:12px;
}