.integration-test.is-mobile .ant-result {
  padding: 16px 16px;
}

.integration-test.is-mobile .ant-result-title {
  font-size: 16px;
}

.integration-test.is-mobile .ant-result-icon i {
  font-size: 48px;
}

.integration-test.is-mobile .ant-result-subtitle .ant-alert {
  padding-left: 48px;
}
