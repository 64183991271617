.content-wrapper {
  min-height: 100vh !important;
}

.header {
  padding: 0 !important;
  width: 100%;
}

.header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  /* width: 100%; */
  transition: width 0.2s;
}

.content {
  padding-top: 0;
  margin: 10px;
}

@media only screen and (min-width: 484px) {
  .content {
    padding-top: 0;
    margin: 24px;
  }
}

.innerContent {
  background-color: #fff;
  padding: 5px;
  min-height: 280px;
}

@media only screen and (min-width: 484px) {
  .innerContent {
    padding: 24px;
  }
}
