.account-header {
  position: fixed;
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  color: #fff;
  background: #01723a;
  z-index: 50;
}

.account-header-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.account-header-wrapper header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
}

.account-header-wrapper header a {
  display: flex;
  height: 34px;
  align-items: center;
  border: 0;
  color: #fff;
  flex-flow: row nowrap;
}

.account-header-wrapper header img {
  height: 100%;
  margin-right: 10px;
}

.account-header-wrapper header h2 {
  display: block;
  position: relative;
  line-height: 18px;
  margin: 0;
  font-size: 1.25em;
  white-space: nowrap;
}

.account-nav-wrapper {
  position: relative;
  height: 34px;
  margin-left: auto;
}

.account-nav-wrapper nav {
  bottom: auto;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  right: auto;
  top: auto;
  width: auto;
  height: auto;
  background: none;
}

.account-nav-wrapper .account-header-dropdown {
  /* display: inline-block; */
  float: right;
}

.account-nav-wrapper nav ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  width: auto;
  background: none;
}

@media only screen and (min-width: 1024px) {
  .account-nav-wrapper nav ul {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
    width: auto;
    background: none;
  }
}

.account-nav-wrapper nav ul li {
  flex: 1 1 auto;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.account-nav-wrapper nav ul li a {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  transition: background-color 0.3s;
  margin: 0;
  padding: 6px 10px;
  height: 32px;
  line-height: 1.2em;
  border: 0;
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 1em;
  font-weight: 300;
}

.account-nav-wrapper nav ul li a:hover {
  color: #ffffff;
}

.account-content {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -250px;
  width: 300px;
  height: 500px;
  padding: 20px;
  color: #eee;
}

.account-content .login-form h3 {
  height: 20px;
  line-height: 20px;
  padding: 0 0 35px 0;
  text-align: center;
  font: 20px "microsoft yahei", Helvetica, Tahoma, Arial, "Microsoft jhengHei",
    sans-serif;
}

.account-content .login-form-forgot {
  float: right;
}

.account-content .login-form-button {
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  font-size: 12px;
  line-height: 70px;
  color: #999;
  text-align: center;
  clear: both;
}
